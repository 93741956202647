import React from "react";

import './App.scss';

var DEFAULT_SITE = "https://svoboda.org"

class OnionButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {url: ""};

        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        var source = document.getElementById("url").value;
        if (source.length == 0)
                source = DEFAULT_SITE;

        fetch_mirror_json(source, "tor")

    }

    render() {
        return (
            <button onClick={this.handleClick} className="btn btn-onion">
                Onion
            </button>
        );
    }
}

class RedirButton extends React.Component {
    constructor(props) {
        super(props);
	this.state = {url:""};
	this.handleClick = this.handleClick.bind(this);
    }

    handleClick () {

        var source = document.getElementById("url").value;
        if (source.length == 0)
                source = DEFAULT_SITE;


	var base = "https://s3.amazonaws.com/anynews/r.html#";
        var finalMirror = base + source;
	document.getElementById("urlmirror").value=finalMirror;
        if (navigator.clipboard.writeText) navigator.clipboard.writeText(finalMirror);
    }

    render() {
        return (
            <button onClick={this.handleClick} className="btn btn-info">
               	Redir 
            </button>
        );
    }
}

class MirrorButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {url: ""};

        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        var source = document.getElementById("url").value;
	if (source.length == 0)
		source = DEFAULT_SITE;

        fetch_mirror_json(source, "https")
    }

    render() {
        return (
            <button onClick={this.handleClick} className="btn btn-success">
                Mirror
            </button>
        );
    }
}

function display_mirror(queryUrl, data, proto) {
    data.sites.forEach(function(s) {
        if (queryUrl.toLowerCase().startsWith("http://"))
            queryUrl = "https://" + queryUrl.substr("http://".length);
        else if (!queryUrl.toLowerCase().startsWith("https://"))
            queryUrl = "https://" + queryUrl;


        var mirrorUrl = new URL(queryUrl);
       if (mirrorUrl.hostname === s.main_domain || mirrorUrl.hostname === "www." + s.main_domain) {
           s.available_alternatives.forEach(function(a) {
               if (a.proto === proto) {
                   mirrorUrl.host = a.url.substr("https://".length);
                   var finalMirror = mirrorUrl.href
                   document.getElementById("urlmirror").value=finalMirror
                   if (navigator.clipboard.writeText) navigator.clipboard.writeText(finalMirror) 
               }
           })
       }
    });
}

function redirect_to_mirror(queryUrl, data, proto) {
    data.sites.forEach(function(s) {
        if (queryUrl.toLowerCase().startsWith("http://"))
            queryUrl = "https://" + queryUrl.substr("http://".length);
        else if (!queryUrl.toLowerCase().startsWith("https://"))
            queryUrl = "https://" + queryUrl;
        var mirrorUrl = new URL(queryUrl);
       if (mirrorUrl.hostname === s.main_domain || mirrorUrl.hostname === "www." + s.main_domain) {
           s.available_alternatives.forEach(function(a) {
               if (a.proto === proto) {
                   mirrorUrl.host = a.url.substr("https://".length);
                   window.location = mirrorUrl.href
               }
           })
       }
    });
}

function fetch_mirror_json(url, proto) {
    const json_url = "https://cdn.statically.io/gl/b4754/bootjs/main/.jslint.json";
    fetch(json_url)
        .then(response => response.json())
        .then(data => display_mirror(url, data, proto));
}

function App() {
    return (
        <>
        <header className="header-logo">
            <img alt="Bypass Censorship" src="logo.png" />
        </header>
        <main className="bg-dark">
            <div className="container">
                <div className="row">
		    <p className="text-light"><h1>Bypass Censorship Link Generator</h1></p>
                    <p className="text-light">Enter a URL in the box below, and click
                        <span className="badge bg-info m-1">Redir</span>
                        for a smart redirector link,
                        <span className="badge bg-success m-1">Mirror</span>
                        for a direct mirror link, or
                        <span className="badge m-1 bg-onion">Onion</span>
                        for an onion address link:
                    </p>
                    <div className="search-box">
                        <div className="input-group">
                            <input type="text" placeholder="https://www.svoboda.org/" id="url" autoComplete="off"
                                   className="form-control"/>
                            <RedirButton />
                            <MirrorButton />
                            <OnionButton />
                        </div>
	
 			<div className="input-group"><br/><br/></div>
 			<div className="input-group">
                            <input type="text" placeholder="mirror url will be displayed here and copied to clipboard" id="urlmirror" readonly className="form-control"/>
			</div>
	
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col text-light">
                        <h5>About Redirector and Mirrors</h5>
                        <p>
                            These can be accessed through a normal web browser. They use frequently changing URLs
                            to evade censorship. The Redirector links are very difficult to block, and will find a working Mirror for the user at the time they click on the link. The Mirror links are simpler, and take the user directly to a mirror, but will have a limited lifetime as, once discovered, they can be blocked by censors.
                        </p>
                    </div>
                    <div className="col text-light">
                        <h5>About Onion Services</h5>
                        <p>
                            These require special software to access, such as Tor Browser, Onion Browser or Orbot VPN. The URL is fixed and cannot be censored.
                            Most functionality, even on complex websites, will be functional. Users will need to
                            access the Tor network to use an onion service, and such access <em>can</em> be impaired
                            by censorship.
                        </p>
                    </div>
                </div>
            </div>
        </main>
        </>
    );
}

export default App;
